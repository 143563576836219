import React from "react";
import classNames from "classnames";
import Header from "../HomePageView/components/Header";
import Footer from "../HomePageView/components/Footer";
import styles from "./ContactUsView.module.scss";

const ContactUsView = () => {
  return (
    <>
      <Header />
      <div className={styles.contactUsView}>
        <div className={styles.contactUsHeadLine}>
          <h2>Contact Us</h2>
        </div>
        <div className={styles.contactUsContainer}>
          <div className={styles.contactUsCard}>
            <div className={styles.nectarIcon}>
              <i className={classNames("fa fa-mobile")}></i>
            </div>
            <p>
              <a href="tel:6031112298">+91 9875192122</a>
            </p>
          </div>
          <div className={styles.contactUsCard}>
            <div className={styles.nectarIcon}>
              <i className={classNames("fa fa-envelope-open")}></i>
            </div>
            <p>
              {" "}
              <a href="mail:childrentrafficpark@gmail.com">
                childrentrafficpark@gmail.com
              </a>
            </p>
          </div>
          <div className={classNames(styles.contactUsCard, styles.addressCard)}>
            <div className={styles.nectarIcon}>
              <i className={classNames("fa fa-map-marker")}></i>
            </div>
            <p>
              <a
                target="_blank"
                href="https://maps.app.goo.gl/5XZfmMy1Qh8ikLmm7"
              >
                Children Traffic Park, Bhutdi Jampa Police Quarters Compound,
                Behind Mahila Police Station, Traffic Office (East Zone),
                Karelibaug, Vadodara-390005
              </a>
            </p>
          </div>
        </div>
        <div className={styles.mapContainer}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14765.017728857156!2d73.2057023!3d22.3062153!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fcf2bfb196ffb%3A0xb0b985f264774aa1!2sChildren%20Traffic%20Park!5e0!3m2!1sen!2sin!4v1713326461700!5m2!1sen!2sin"
            width={"100%"}
            height={350}
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUsView;
